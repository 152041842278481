<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll-nav ref="nav" current="基本信息" v-if="detail">
        <cube-scroll-nav-panel key="基本信息" label="基本信息">
          <inputItem title="需求单号" :initialContent="detail.code" :disabled="true"></inputItem>
          <inputItem title="申请日期" :initialContent="formatDate(detail.time)" :disabled="true"></inputItem>
          <inputItem title="单据类型" :initialContent="detail.type==1?'设备借用':'备货需求'" :disabled="true"></inputItem>
          <inputItem title="业务归属" v-model="detail.owner" type="select" :options="ownerOption" :disabled="disabled"></inputItem>
          <inputItem title="项目名称" :initialContent="detail.project_name" :disabled="disabled" type="others" ref="project_name" :otherClicked="clickProject"></inputItem>
          <inputItem title="客户名称" :initialContent="detail.customer_name" :disabled="disabled" type="others" ref="customer_name" :otherClicked="clickCustomer"></inputItem>
          <inputItem title="需求备注" v-model="detail.remark" :disabled="disabled"></inputItem>
          <inputItem title="经办人员" :initialContent="detail.user_name" :disabled="true"></inputItem>
          <div class="flex-col text-14 white-bg-color">
            <div class="flex-row">
              <div class="flex-row width-half space-between">
                <div class="padding-10">合计数量</div>
                <div class="padding-10 red-font-color text-16 bold">
                  {{ formatMoney(detail.goods_num, 0) }}
                </div>
              </div>
              <div class="flex-row width-half space-between">
                <div class="padding-10">合计金额</div>
                <div class="padding-10 red-font-color text-16 bold">
                  {{ formatMoney(detail.goods_price, 2) }}
                </div>
              </div>
            </div>
          </div>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="需求明细" label="需求明细">
          <div class="mobile-detail-list">
            <div class="list-item" v-for="(item, index) in items" :key="index">
              <div class="item-header">
                <div class="item-index">商品{{ index + 1 }}</div>
                <img class="item-delete" src="@/static/images/icon_delete.png" alt @click="deleteItem(index)" v-if="!disabled" />
              </div>
              <div class="item-line">
                <div class="item-lable"> 商品名称 </div>
                <input class="input-left" v-model="item.goods_name" :disabled="disabled" />
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 备货数量 </div>
                  <input class="input-right" v-model="item.num" :disabled="disabled" @input="changeNum(item)" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 备货单价 </div>
                  <input class="input-right" v-model="item.price" :disabled="disabled" @input="changeNum(item)" />
                </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 备货金额 </div>
                  <input class="input-right" v-model="item.total" :disabled="disabled" @input="changeTotal(item)" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 商品备注 </div>
                  <input class="input-left" v-model="item.remark" :disabled="disabled" />
                </div>
              </div>
            </div>
            <div class="item-add" v-if="!disabled" alt @click="addItem"> 添加明细 </div>
          </div>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="审批流程" label="审批流程" v-if="flows && flows.flowLogs && flows.flowLogs.length > 0">
          <div class="mobile-detail-step">
            <Steps :current="flows.step" :status="flows.status" direction="vertical">
              <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
            </Steps>
          </div>
        </cube-scroll-nav-panel>
      </cube-scroll-nav>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
    <optionDrawer ref="optionDrawerProject" v-if="showOptionsProject" :onBgClicked="onBgClickedProject" :onSearch="onSearchProject" :items="projects" @optionClicked="optionClickedProject"> </optionDrawer>
    <optionDrawer ref="optionDrawerCustomer" v-if="showOptionsCustomer" :onBgClicked="onBgClickedCustomer" :onSearch="onSearchCustomer" :items="customers" @optionClicked="optionClickedCustomer"> </optionDrawer>
  </div>
</template>

<script>
import { GetStockDemand, SaveStockDemand, AuditStockDemand, CancelStockDemand } from "@/api";
import { formatDate, formatMoney } from "@/utils/format.js";
import { loadDetailAction, saveAction, auditAction, cancelAction, loadDataOptionAction, loadProjectOptionAction, loadCustomerOptionAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      items: [],
      flows: [],
      buttons: [],
      ownerOption: [],
      customers: [],
      showOptionsCustomer: false,
      projects: [],
      showOptionsProject: false,
      disabled: false,
      itemIndex: -1,
    };
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
    addItem: function () {
      this.items.push({ goods_name: "", num: 1, price: 0, total: 0 });
    },
    deleteItem: function (index) {
      this.items.splice(index, 1);
      this.setTotal();
    },
    changeNum: function (item) {
      item.total = item.price * item.num;
      this.setTotal();
    },
    changeTotal: function (item) {
      item.price = item.total / item.num;
      this.setTotal();
    },
    setTotal: function () {
      var that = this;
      that.detail.goods_num = 0;
      that.detail.goods_price = 0;
      that.items.forEach(function (item) {
        that.detail.goods_num = Number(item.num);
        that.detail.goods_price += Number(item.total);
      });
    },
    onSearchProject: function (value) {
      loadProjectOptionAction(this, value, (data) => { this.projects = data; });
    },
    onBgClickedProject: function () {
      this.showOptionsProject = false;
    },
    optionClickedProject: function (item) {
      this.detail.project_id = item.id;
      this.detail.project_name = item.name;
      this.detail.customer_id = item.customer_id;
      this.detail.customer_name = item.customer_name;
      this.$refs.project_name.setContent(item.name);
      this.showOptionsProject = false;
    },
    clickProject: function () {
      this.showOptionsProject = true;
      this.$nextTick(() => { setTimeout(() => { this.$refs.optionDrawerProject.show(); }, 100); });
    },
    onSearchCustomer: function (value) {
      loadCustomerOptionAction(this, value, (data) => { this.customers = data; });
    },
    onBgClickedCustomer: function () {
      this.showOptionsCustomer = false;
    },
    optionClickedCustomer: function (item) {
      this.detail.customer_id = item.id;
      this.detail.customer_name = item.name;
      this.$refs.customer_name.setContent(item.name);
      this.showOptionsCustomer = false;
    },
    clickCustomer: function () {
      this.showOptionsCustomer = true;
      this.$nextTick(() => { setTimeout(() => { this.$refs.optionDrawerCustomer.show(); }, 100); });
    },
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveStockDemand(1);
          break;
        case "提交审核":
          this.saveStockDemand(2);
          break;
        case "审核通过":
          this.auditStockDemand(true, "同意");
          break;
        case "审核拒绝":
          this.auditStockDemand(false, "");
          break;
        case "撤回":
          this.cancelStockDemand();
          break;
      }
    },
    saveStockDemand: function (state) {
      this.detail.state = state;
      saveAction(this, SaveStockDemand, { model: this.detail, items: this.items }, () => { this.$router.go(-1); }, () => { this.detail.state = 1; });
    },
    auditStockDemand: function (pass, memo) {
      auditAction(this, AuditStockDemand, this.id, pass, memo);
    },
    cancelStockDemand: function () {
      cancelAction(this, CancelStockDemand, this.id);
    },
    dataRequest: function () {
      loadDetailAction(this, GetStockDemand, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.items = data.list[0].items;
        this.flows = data.list[0].flows;
        this.buttons = data.list[0].buttons;
        this.disabled = !this.buttons || this.buttons.indexOf("保存草稿") < 0;
        this.$nextTick(() => { this.$refs.nav.refresh(); });
      });
    },
  },
  mounted() {
    loadDataOptionAction(this, "业务归属", (data) => { this.ownerOption = data; });

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>